import { usePortalTranslation } from '@hooks/use-portal-translation';

import { Input, Select, Typography } from '@destination/components';

import { DeviceModelParameters, OverridableEnum } from '@omnis-pulse-types';

export interface IParameterInputField {
  param: DeviceModelParameters;
  localValue?: string;
  isDisabled: boolean;
  handleChange: (key: string, value: string) => void;
}

export const ParameterInputField = ({
  param,
  localValue,
  isDisabled,
  handleChange
}: IParameterInputField) => {
  const { t, t_generic } = usePortalTranslation('gridMeters');

  const isRequired = param.overridable === OverridableEnum.MANDATORY;

  return (
    <div key={param.name}>
      <Typography>
        {t_generic(`literals.${param.name}`, { titleize: true })}
        {isRequired ? '*' : ''}
      </Typography>
      <div className="w-full max-w-[342px]">
        {param.possibleValues && param.possibleValues.length > 0 ? (
          <Select
            options={[
              ...param.possibleValues.map(value => ({
                label: value,
                value
              }))
            ]}
            selected={
              localValue
                ? { label: localValue, value: localValue }
                : { label: t_generic('labels.select'), value: '' }
            }
            onChange={option =>
              handleChange(param.name, option?.value.toString() || '')
            }
            suffixText={param.unit ?? ''}
            disabled={
              isDisabled || param.overridable === OverridableEnum.NOT_ALLOWED
            }
            data-testid={`select-${param.name}`}
          />
        ) : (
          <Input
            id={param.name}
            value={localValue}
            type={param.type === 'string' ? 'text' : 'number'}
            onBlur={e => {
              if (e.target.value.trim() === '') {
                handleChange(param.name, param.defaultValue);
              }
            }}
            onChange={e => handleChange(param.name, e.target.value)}
            suffixText={param.unit ?? ''}
            disabled={
              isDisabled || param.overridable === OverridableEnum.NOT_ALLOWED
            }
            data-testid={`input-${param.name}`}
            helperText={
              param.defaultValue &&
              t('inputs.helper_text', { default: param.defaultValue })
            }
          />
        )}
      </div>
    </div>
  );
};
