import { ReactElement } from 'react';

import { usePortalTranslation } from '@hooks/use-portal-translation';

import ErrorIcon from '@icons/red_error_16.svg';
import WarningIcon from '@icons/warning_16.svg';
import { dateTimeFormat, formatDate } from '@utils/date-utils';

import { Grid } from '@destination/components';

import { EventLevel, EventLogRecord } from '@omnis-pulse-types';

export interface ILogsTableRow {
  index: number;
  log: EventLogRecord;
}

export const LogsTableRow = ({ index, log }: ILogsTableRow) => {
  const { timestamp, level, code, message } = log;

  const { t } = usePortalTranslation();
  const levelLabel = t(`states.${level.toLowerCase()}`, { titleize: true });

  return (
    <Grid.TableRow
      data-testid="log-table-row"
      alternateRowColor={index % 2 !== 0}
    >
      <Grid.DataCell>
        {timestamp && formatDate(timestamp, dateTimeFormat)}
      </Grid.DataCell>
      <Grid.DataCell>
        <div className="flex items-center gap-[4px]">
          {levelIcons[level]}
          {levelLabel}
        </div>
      </Grid.DataCell>
      <Grid.DataCell>{code}</Grid.DataCell>
      <Grid.DataCell>{message}</Grid.DataCell>
    </Grid.TableRow>
  );
};

const levelIcons: Record<EventLevel, ReactElement> = {
  [EventLevel.INFO]: <div className="w-[18px]"></div>,
  [EventLevel.ERROR]: <ErrorIcon className="text-[#BABABA]" />,
  [EventLevel.WARN]: <WarningIcon className="text-[#BABABA]" />
};
