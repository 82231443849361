import { useParams } from 'react-router-dom';

import useSiteLocalModbusInterface from '@api/sites/use-site-local-modbus-interface';

import { Card, DataError, LoadingSkeleton } from '@destination/components';

import { LocalInterfaceSwitch } from './LocalInterfaceSwitch';

export const LocalModbusInterface = () => {
  const { id } = useParams();
  const { data, isLoading, isError, isNotFound, mutate } =
    useSiteLocalModbusInterface(id);

  const handleSave = async () => {
    if (id) {
      await mutate();
    }
  };

  const renderContent = () => {
    if (isLoading) {
      return <LoadingSkeleton height={20} />;
    }

    if (isError) {
      return <DataError onRefresh={() => mutate(undefined, true)} />;
    }

    if (data === undefined) {
      return null;
    }

    return (
      <div className="flex items-center gap-4 rounded-[4px] bg-[#FAFAFA] px-3 py-4">
        <LocalInterfaceSwitch
          isEnabled={data?.enabled ?? false}
          onSave={handleSave}
        />
      </div>
    );
  };

  if (isNotFound) {
    return null;
  }

  return (
    <Card data-testid="local-modbus-interface-card" className="w-[620px]">
      {renderContent()}
    </Card>
  );
};
