import { useEffect } from 'react';

import { useNavigate } from 'react-router-dom';

import { Disclaimer } from '@destination/components';

export const DelayedNavigationDisclaimer = ({
  message,
  navigateBack = true,
  path
}: {
  message: string;
  navigateBack?: boolean;
  path?: string;
}) => {
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setTimeout(() => {
      if (!navigateBack && path) {
        navigate(path);
      } else {
        navigate(-1);
      }
    }, 10000);

    return () => clearTimeout(timer);
  }, [navigate, navigateBack, path]);

  return <Disclaimer variant={'warning'} message={message} />;
};
