import { usePortalTranslation } from '@hooks/use-portal-translation';
import { Link, useParams } from 'react-router-dom';

import useChargers from '@api/sites/chargers/use-chargers';
import useSiteGateways from '@api/sites/use-site-gateways';
import OnlineIcon from '@icons/online_16.svg';
import ErrorIcon from '@icons/red_error_16.svg';
import { titleize } from '@utils/i18n-utils';

import {
  Card,
  DataError,
  LoadingSkeleton,
  Typography
} from '@destination/components';

import { ConnectionStatusEnum } from '@omnis-pulse-types';

export const GatewayCard = () => {
  const { id } = useParams();

  const { gateways, isLoading, isError, mutate } = useSiteGateways(id);
  const { chargers } = useChargers(id);

  if (isLoading) {
    return (
      <LoadingSkeleton
        isDarker
        className="w-[235px] rounded-[10px]"
        height={115}
      />
    );
  }

  if (isError)
    return (
      <div className="flex h-[115px] w-[235px] items-center justify-center">
        <DataError onRefresh={() => mutate(undefined, true)} />
      </div>
    );

  if (!gateways || gateways.length === 0) return null;

  const gatewayOnlineStatus = gateways[0].connectionStatus.find(
    status => status.type === 'ABB'
  );
  const isOffline =
    gatewayOnlineStatus &&
    gatewayOnlineStatus.status !== ConnectionStatusEnum.CONNECTED;

  const notConnectedChargers =
    chargers?.reduce((acc, { connectionStatus: { status } }) => {
      if (status !== ConnectionStatusEnum.CONNECTED) return ++acc;
      return acc;
    }, 0) ?? 0;

  return (
    <Link to="devices/gateways" data-testid="gateways-link">
      <Card className="h-[115px] w-[235px] !pl-3 hover:cursor-pointer hover:drop-shadow-hover">
        <Typography variant="smallTitle" className="mb-1.5">
          Terra Gateway Pro
        </Typography>
        {isOffline && <NotConnected />}
        {!isOffline && notConnectedChargers > 0 && (
          <NotConnected notConnectedChargers={notConnectedChargers} />
        )}
        {!isOffline && notConnectedChargers === 0 && <AllConnected />}
      </Card>
    </Link>
  );
};

interface INotConnected {
  notConnectedChargers?: number;
}

const NotConnected = ({ notConnectedChargers }: INotConnected) => {
  const { t, t_generic } = usePortalTranslation('gateways');

  if (notConnectedChargers && notConnectedChargers > 0) {
    return (
      <Status
        variant="error"
        label={t('labels.devices_not_connected', {
          count: notConnectedChargers
        })}
        isFontBold
      />
    );
  }

  return (
    <Status
      variant="error"
      label={titleize(t_generic('states.error'))}
      isFontBold
    />
  );
};

const AllConnected = () => {
  const { t } = usePortalTranslation();

  return <Status variant="online" label={titleize(t('states.online'))} />;
};

interface IStatus {
  variant: 'online' | 'error';
  label: string;
  isFontBold?: boolean;
}

const Status = ({ variant, label, isFontBold = false }: IStatus) => {
  const classes = ['text-[12px]'];

  if (isFontBold) classes.push('font-bold');

  return (
    <div className="flex items-center gap-1">
      {variant === 'online' ? (
        <OnlineIcon data-testid="all-connected-icon" />
      ) : (
        <ErrorIcon data-testid="not-connected-icon" />
      )}
      <span className={classes.join(' ')}>{label}</span>
    </div>
  );
};
