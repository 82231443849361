import { GRID_METERS_BY_SITE_ID_PATH } from '@api/paths';
import { modifier } from '@utils/swr-utils';

import { UpdateGridMeter } from '@omnis-pulse-types';

export default function updateGridMeter(
  siteId: string,
  payload: UpdateGridMeter
) {
  const url = GRID_METERS_BY_SITE_ID_PATH(siteId);
  return modifier(url, 'PATCH', payload);
}
