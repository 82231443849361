import { useEffect, useMemo, useRef } from 'react';

import { usePortalTranslation } from '@hooks/use-portal-translation';
import { useOutletContext, useParams } from 'react-router-dom';
import { mutate } from 'swr';

import { GRID_METERS_BY_SITE_ID_PATH } from '@api/paths';
import useProductTypeDeviceModels from '@api/sites/devices/use-product-type-device-models';
import updateGridMeter from '@api/sites/grid-meter/update-grid-meter';
import { GridMeterActionButtons } from '@components/grid-meters/add-grid-meter/GridMeterActionButtons';
import { GridMeterParameters } from '@components/grid-meters/add-grid-meter/GridMeterParameters';
import { GridMeterProtocolCard } from '@components/grid-meters/add-grid-meter/GridMeterProtocolCard';
import {
  filterConfigurableParameters,
  useGridMeterContext
} from '@context/GridMeterContext';

import { Card, Typography, useAlert } from '@destination/components';

import { GridMeter as GridMeterModel } from '@omnis-pulse-types';

export const UpdateGridMeter = () => {
  const { id = '' } = useParams();
  const gridMeter = useOutletContext<GridMeterModel>();

  const { t, t_generic } = usePortalTranslation('gridMeters');
  const { notifySuccess, notifyError } = useAlert();

  const { deviceModels, isLoading } = useProductTypeDeviceModels(
    id,
    gridMeter.parameters.productIdentity
  );

  const {
    setSelectedProtocol,
    selectedProtocol,
    updateExtraParameters,
    updateGridMeterParameters,
    gridMeterParameters,
    setIsProcessing,
    isProcessing,
    isValidForm
  } = useGridMeterContext();

  useEffect(() => {
    setSelectedProtocol(gridMeter.parameters.variant.name);
  }, [gridMeter.parameters.variant, setSelectedProtocol]);

  const hasInitialized = useRef(false);

  const parameters = useMemo(
    () =>
      filterConfigurableParameters(
        deviceModels?.variants.find(
          variant => variant.name === selectedProtocol
        )?.parameters || []
      ),
    [deviceModels, selectedProtocol]
  );

  const protocols = useMemo(
    () =>
      deviceModels?.variants
        .map(variant => variant.name)
        .filter(variant => variant === 'tcp') ?? [],
    [deviceModels]
  );

  useEffect(() => {
    if (!hasInitialized.current && deviceModels) {
      const existingVariantParams =
        gridMeter.parameters.variant.parameters || [];
      const existingExtraParams = gridMeter.parameters.extraParameters || [];

      const existingVariantParamMap = new Map(
        existingVariantParams.map(param => [param.name, param.value])
      );
      const existingExtraParamMap = new Map(
        existingExtraParams.map(param => [param.name, param.value])
      );

      const mergedVariantParams =
        parameters?.map(param => ({
          name: param.name,
          value: existingVariantParamMap.get(param.name) ?? param.defaultValue
        })) || existingVariantParams;

      const mergedExtraParams =
        deviceModels.extraParameters
          ?.map(param => {
            const name = param.name;
            const value =
              existingExtraParamMap.get(name) ?? param.defaultValue ?? '';

            return {
              name,
              value
            };
          })
          ?.filter(param => param.name && param.value) ||
        existingExtraParams?.map(extra => ({
          name: extra.name ?? '',
          value: extra.value ?? ''
        }));

      updateGridMeterParameters(mergedVariantParams);
      updateExtraParameters(mergedExtraParams);

      hasInitialized.current = true;
    }
  }, [
    gridMeter.parameters.extraParameters,
    gridMeter.parameters.variant.parameters,
    updateExtraParameters,
    updateGridMeterParameters,
    parameters,
    deviceModels
  ]);

  const handleUpdateGridMeter = async () => {
    setIsProcessing(true);
    const { error } = await updateGridMeter(id, {
      instance_id: gridMeter.instanceId,
      protocol: gridMeterParameters.protocol,
      specifications: gridMeterParameters.specifications
    });

    if (error) {
      notifyError({ header: t('notifications.error_updating_grid_meter') });
      setIsProcessing(false);
      return;
    }

    notifySuccess({ header: t('notifications.grid_meter_updated') });
    await mutate(GRID_METERS_BY_SITE_ID_PATH);
    setIsProcessing(false);
  };

  return (
    <div className="flex flex-col gap-6">
      <Card className="max-w-[620px] py-6">
        <div className="[&>div:nth-child(odd)]:bg-[#FAFAFA] [&>div]:flex [&>div]:flex-col [&>div]:justify-between [&>div]:gap-y-2 [&>div]:rounded-[4px] [&>div]:py-2 [&>div]:pl-3 [&>div]:pr-[18px] [&>div]:md:flex-row [&>div]:md:items-center">
          <div>
            <Typography>
              {t_generic('literals.manufacturer', { titleize: true })}
            </Typography>

            <div className="w-full max-w-[342px]">{gridMeter.manufacturer}</div>
          </div>
          <div>
            <Typography>
              {t_generic('literals.type', { titleize: true })}
            </Typography>
            <div className="w-full max-w-[342px]">
              {gridMeter.parameters.type}
            </div>
          </div>
        </div>
      </Card>
      <GridMeterProtocolCard
        isDisabled={isProcessing}
        isLoading={isLoading}
        protocols={protocols}
      />
      {!isLoading && selectedProtocol && parameters && (
        <GridMeterParameters
          parameters={parameters}
          extraParameters={deviceModels?.extraParameters}
          isLoading={isLoading}
          isDisabled={isProcessing}
          fillInDefaultValues={false}
        />
      )}
      <GridMeterActionButtons
        isProcessing={isProcessing}
        isConfirmDisabled={!isValidForm}
        onConfirm={handleUpdateGridMeter}
        variant="update"
      />
    </div>
  );
};
