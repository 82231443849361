import {
  addDays,
  addHours,
  differenceInDays,
  eachHourOfInterval,
  endOfYear,
  differenceInMinutes as getDifferenceInMinutes,
  getHours,
  getMinutes,
  getTime,
  setHours,
  setMinutes,
  setSeconds,
  startOfDay,
  startOfTomorrow,
  startOfYear
} from 'date-fns';

import { formatDate, monthDayHourMinuteFormat, timeFormat } from './date-utils';

export type dateFilterOption =
  | 'today'
  | 'last7days'
  | 'last30days'
  | 'year'
  | 'allTime';

export const donutChartConfig = (
  total: number,
  value: number,
  fill: string,
  title: string
) => ({
  data: [{ value, fill }],
  restValue: total - value,
  title
});

export const tickFormatter = (
  domain: number[] | undefined,
  selectedDateFilter: dateFilterOption
) => {
  if (selectedDateFilter === 'today') {
    return (tick: string) => formatDate(tick, timeFormat);
  }

  if (domain && differenceInDays(domain[1], domain[0]) <= 2) {
    return (tick: string) => formatDate(tick, monthDayHourMinuteFormat);
  }

  return (tick: string) => formatDate(tick);
};

export const generateXAxisTicksForZoomAndHighlight = (
  domain: number[]
): number[] => {
  const differenceInMinutes = getDifferenceInMinutes(domain[1], domain[0]);

  let interval = 1440;
  let lastTickTimestamp = setMinutes(setSeconds(domain[1], 0), 0).getTime();

  if (differenceInMinutes < 5) {
    interval = 1;
    lastTickTimestamp = setSeconds(domain[1], 0).getTime();
  } else if (differenceInMinutes < 10) {
    interval = 2;
    lastTickTimestamp = setMinutes(
      lastTickTimestamp,
      getMinutes(domain[1]) - (getMinutes(domain[1]) % 2)
    ).getTime();
  } else if (differenceInMinutes < 30) {
    interval = 5;
    lastTickTimestamp = setMinutes(
      lastTickTimestamp,
      getMinutes(domain[1]) - (getMinutes(domain[1]) % 5)
    ).getTime();
  } else if (differenceInMinutes < 60) {
    interval = 10;
    lastTickTimestamp = setMinutes(
      lastTickTimestamp,
      Math.floor(getMinutes(domain[1]) / 10) * 10
    ).getTime();
  } else if (differenceInMinutes < 360) {
    interval = 30;
    lastTickTimestamp = setMinutes(
      lastTickTimestamp,
      getMinutes(domain[1]) < 30 ? 0 : 30
    ).getTime();
  } else if (differenceInMinutes < 720) {
    interval = 60;
    lastTickTimestamp = setHours(
      lastTickTimestamp,
      getHours(domain[1])
    ).getTime();
  } else if (differenceInMinutes < 1440) {
    interval = 120;
    lastTickTimestamp = setHours(
      lastTickTimestamp,
      getHours(domain[1]) - (getHours(domain[1]) % 2)
    ).getTime();
  } else if (differenceInMinutes < 2880) {
    interval = 360;
    lastTickTimestamp = setHours(
      lastTickTimestamp,
      getHours(domain[1]) - (getHours(domain[1]) % 6)
    ).getTime();
  }

  const numberOfTicks = Math.floor(differenceInMinutes / interval) + 1;
  const ticks = new Set<number>();

  for (let i = 0; i < numberOfTicks; i++) {
    const timestamp = lastTickTimestamp - i * interval * 60 * 1000;
    const tick = getTime(new Date(timestamp));
    if (tick >= domain[0]) {
      ticks.add(tick);
    }
  }

  return Array.from(ticks).sort((a, b) => a - b);
};

export const generateLast24HoursXAxisTicks = (): number[] => {
  const currentDate = new Date();

  const start = addHours(currentDate, -23);
  const end = addHours(currentDate, 1);

  const ticks = eachHourOfInterval({ start, end }).map(date => date.valueOf());
  return ticks;
};

export const generateXAxisTicks = (filter?: dateFilterOption): number[] => {
  let finalTimestamp: number;
  let numberOfTicks: number;
  let numberOfDays: number;

  const currentDate = new Date();

  switch (filter) {
    case 'today':
      finalTimestamp = startOfTomorrow().getTime();
      numberOfTicks = 12;
      numberOfDays = 1;
      break;
    case 'last7days':
      finalTimestamp = currentDate.getTime();
      numberOfTicks = 7;
      numberOfDays = 7;
      break;
    case 'last30days':
      finalTimestamp = currentDate.getTime();
      numberOfTicks = 3;
      numberOfDays = 30;
      break;
    case 'year':
      finalTimestamp = startOfDay(addDays(endOfYear(currentDate), 1)).getTime();
      numberOfTicks = 3;
      numberOfDays =
        differenceInDays(endOfYear(currentDate), startOfYear(currentDate)) + 1;
      break;
    case 'allTime':
      finalTimestamp = currentDate.getTime();
      numberOfTicks = 3;
      numberOfDays =
        differenceInDays(
          currentDate,
          startOfDay(new Date('2023-01-01T00:00:00'))
        ) + 1;
      break;
    default:
      finalTimestamp = currentDate.getTime();
      numberOfTicks = 12;
      numberOfDays = 1;
      break;
  }

  const intervalInHours = Math.floor(
    calculateIntervalInHours(numberOfTicks, numberOfDays)
  );

  const ticks = new Set<number>();

  for (let i = 0; i <= numberOfTicks; i++) {
    const timestamp = finalTimestamp - i * intervalInHours * 60 * 60 * 1000;

    if (!filter || filter === 'today') {
      ticks.add(getTime(new Date(timestamp).setMinutes(0)));
    } else {
      ticks.add(getTime(setHours(new Date(timestamp).setMinutes(0), 0)));
    }
  }

  return Array.from(ticks).sort((a, b) => a - b);
};

const calculateIntervalInHours = (ticks: number, daysShown: number): number => {
  const totalHoursInDays = daysShown * 24;
  const intervalInHours = totalHoursInDays / ticks;
  return intervalInHours;
};
