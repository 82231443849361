import { usePortalTranslation } from '@hooks/use-portal-translation';
import { Outlet, useParams } from 'react-router-dom';

import useSiteGridMeter from '@api/sites/use-site-grid-meter';
import { CouldNotLoadData } from '@components/ui/CouldNotLoadData';

import { LoadingSkeleton, Typography } from '@destination/components';

import { GridMeterStatus } from './GridMeterStatus';

export const GridMeterDetails = () => {
  const { id } = useParams();
  const { gridMeters, isLoading, isError, mutate } = useSiteGridMeter(id);

  const { t } = usePortalTranslation();

  return (
    <>
      <div className="mb-[17px] mt-2 flex flex-col justify-between gap-6 md:flex-row [&>div:first-child]:w-[250px]">
        <Typography variant="sectionTitle">
          {t('literals.grid_meter', { titleize: true })}
        </Typography>
        {gridMeters && <GridMeterStatus gridMeter={gridMeters[0]} />}
      </div>
      {isLoading && <LoadingSkeleton className="rounded-[10px]" height={431} />}
      {isError && <CouldNotLoadData className="m-auto" mutate={mutate} />}
      {gridMeters && <Outlet context={gridMeters[0]} />}
    </>
  );
};
