import { useMemo } from 'react';

import { useFilter } from '@hooks/use-filter';
import { usePortalTranslation } from '@hooks/use-portal-translation';

import { FilterButton } from '@destination/components';

import { EventLevel } from '@omnis-pulse-types';

export const LevelFilterButton = () => {
  const { t } = usePortalTranslation();

  const options = useMemo(
    () =>
      Object.values(EventLevel).map(level => ({
        label: t(`states.${level.toLowerCase()}`, { titleize: true }),
        value: level
      })),
    [t]
  );

  const { selectedOptions, handleFilter } = useFilter('level', options);

  let buttonLabel = t('literals.level', { titleize: true });
  if (selectedOptions.length === 1) {
    buttonLabel = `${selectedOptions[0].label}`;
  } else if (selectedOptions.length > 1) {
    buttonLabel = t('labels.x_levels_selected', {
      count: selectedOptions.length
    });
  }
  const clearLabel = t('buttons.clear_all', { titleize: true });

  return (
    <FilterButton
      options={options}
      selected={selectedOptions}
      onSelect={handleFilter}
      clearLabel={clearLabel}
    >
      {buttonLabel}
    </FilterButton>
  );
};
