import { ReactNode, useContext } from 'react';

import { usePortalTranslation } from '@hooks/use-portal-translation';
import axios from 'axios';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import useUserDetails from '@api/user-account/use-user-details';
import { FooterNavigation } from '@components/navigation/FooterNavigation';
import { MenuNavigation } from '@components/navigation/MenuNavigation';
import { SiteBreadcrumbs } from '@components/sites/SiteBreadcrumbs';
import { UserDetails } from '@components/user/UserDetails';
import { UnsupportedViewportDisclaimer } from '@components/utils/UnsupportedViewportDisclaimer';

import {
  LoadingSkeleton,
  MobileNavigation,
  Sidebar,
  SidebarContext,
  Typography
} from '@destination/components';

import { Unauthorized } from './errors/Unauthorized';
import { UserAccountUnknown } from './errors/UserAccountUnknown';

export const Root = () => {
  const navigate = useNavigate();
  const { t } = usePortalTranslation('errors');

  const { isCollapsed } = useContext(SidebarContext);

  const { error, isLoading } = useUserDetails();

  // base class names
  const baseClasses =
    'flex grow flex-col overflow-y-auto overflow-x-hidden pb-[102px] pt-[9px] transition-all duration-200 min-h-screen';
  // small screen class names
  const smallScreenClasses = 'px-8';
  // large screen class names
  const largeScreenClasses =
    'large-screen:pl-[calc((100vw-1560px)/2)] large-screen:pr-[calc((100vw-1560px)/2)]';
  // collapsed class names
  const collapsedClasses = isCollapsed
    ? 'small-screen:pl-[72px] small-screen:pr-8'
    : 'small-screen:pl-[220px] small-screen:pr-[140px]';

  let content = <Outlet />;

  if (isLoading) {
    content = <LoadingSkeleton height={400} />;
  }
  if (axios.isAxiosError(error)) {
    if (
      error.response?.status == 403 &&
      error.response.data.message ===
        'Organization does not have an active subscription'
    ) {
      content = (
        <Unauthorized
          message={t('your_organization_has_no_active_subscription')}
        />
      );
    }
    if (error.response?.status == 401) {
      content = <UserAccountUnknown />;
    }
  }
  return (
    <div data-testid="page-content" className="flex h-full">
      <div className="hidden small-screen:block">
        <Sidebar
          menuItems={<MenuNavigation isMobile={false} />}
          footerItems={<FooterNavigation isMobile={false} />}
          onClickBranding={() => navigate('/')}
        />
      </div>
      <main
        id="main-content"
        className={`${baseClasses} ${smallScreenClasses} ${largeScreenClasses} ${collapsedClasses}`}
      >
        <UnsupportedViewportDisclaimer />
        <MobileTop />
        <DefaultTop />
        {content}
      </main>
    </div>
  );
};

const MobileTop = () => {
  return (
    <div className="grid grid-cols-2 pb-4 small-screen:hidden">
      <div className="my-auto -ml-2 flex">
        <MobileNavigation
          navigationItems={
            <>
              <MenuNavigation isMobile={true} />
              <FooterNavigation isMobile={true} />
            </>
          }
        />
      </div>
      <div className="ml-auto flex h-10">
        <UserDetails />
      </div>
    </div>
  );
};

const DefaultTop = () => {
  const { t } = usePortalTranslation();
  const { pathname } = useLocation();

  const [rootUrl, ...otherPathSegments] = pathname.split('/').filter(Boolean);

  let breadcrumbs: ReactNode = null;
  if (rootUrl === 'sites') breadcrumbs = <SiteBreadcrumbs />;

  return (
    <div className="hidden grid-cols-3 pb-4 small-screen:grid">
      {otherPathSegments.length !== 0 && breadcrumbs ? (
        breadcrumbs
      ) : (
        <div className="block" />
      )}
      <div className="flex h-8">
        <Typography className="m-auto !leading-4" variant="smallTitle">
          {t('application_name')}
        </Typography>
      </div>
      <div className="ml-auto flex h-10">
        <UserDetails />
      </div>
    </div>
  );
};
