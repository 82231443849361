import useSWR from 'swr';

import { SITE_LOCAL_MODBUS_INTERFACE_PATH } from '@api/paths';

import { SiteLocalInterface } from '@omnis-pulse-types';

export default function useSiteLocalModbusInterface(siteId?: string) {
  const { data, error, isLoading, mutate } = useSWR<SiteLocalInterface>(
    siteId ? SITE_LOCAL_MODBUS_INTERFACE_PATH(siteId) : null
  );

  const isNotFound = error?.status === 404;

  if (isNotFound) {
    return {
      data: undefined,
      isLoading: false,
      isError: false,
      isNotFound,
      mutate
    };
  }

  return {
    data,
    isLoading,
    isError: Boolean(error),
    isNotFound,
    mutate
  };
}
