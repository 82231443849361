import { rand, randNumber } from '@ngneat/falso';

import {
  ActiveLoadManagementMode,
  ChargingStrategy,
  SiteChargingStrategy,
  SiteLoadManagementLimits
} from '@omnis-pulse-types';

export class LoadManagementMockFactory {
  static createSiteLoadManagementLimits(
    data?: Partial<SiteLoadManagementLimits>
  ): SiteLoadManagementLimits {
    const randMode = rand(Object.values(ActiveLoadManagementMode));
    const randGridConnectionLimits = randNumber({
      min: 16,
      max: 600,
      length: 2
    });
    const randSiteAuxiliaryCurrent = randNumber({
      min: 4,
      max: randGridConnectionLimits[0]
    });
    const randFallbackChargingBudget = randNumber({
      min: 4,
      max: randGridConnectionLimits[1]
    });

    return {
      activeMode: randMode,
      staticGridConnectionLimit: randGridConnectionLimits[0],
      siteAuxiliaryCurrent: randSiteAuxiliaryCurrent,
      chargingBudgetLimit:
        randGridConnectionLimits[0] - randSiteAuxiliaryCurrent,
      dynamicGridConnectionLimit: randGridConnectionLimits[1],
      fallbackChargingBudgetLimit: randFallbackChargingBudget,
      ...data
    };
  }

  static createSiteLoadManagementChargingStrategy(
    data?: Partial<SiteChargingStrategy>
  ): SiteChargingStrategy {
    const randChargeStrategy = rand([
      ChargingStrategy.EQUAL_POWER,
      ChargingStrategy.EQUAL_SHARE,
      ChargingStrategy.FIFO
    ]);
    const randBasePower = randNumber({
      min: 4,
      max: 20
    });

    return {
      strategy: randChargeStrategy,
      basePower: randBasePower,
      ...data
    };
  }
}
