import { usePortalTranslation } from '@hooks/use-portal-translation';
import { NavLink } from 'react-router-dom';

import { Button } from '@destination/components';

export interface IGridMeterActionButtons {
  isProcessing: boolean;
  isConfirmDisabled: boolean;
  onConfirm: () => void;
  variant?: 'add' | 'update';
}

export const GridMeterActionButtons = ({
  isProcessing,
  isConfirmDisabled,
  onConfirm,
  variant = 'add'
}: IGridMeterActionButtons) => {
  const { t } = usePortalTranslation();

  const cancelButtonLabel = t('buttons.cancel', { titleize: true });

  const actionButtonLabel =
    variant === 'update'
      ? t('buttons.save', {
          entity: t('literals.grid_meter'),
          titleize: true
        })
      : t('buttons.add', { entity: t('literals.grid_meter'), titleize: true });

  return (
    <div className="mt-12 flex justify-center gap-6">
      <NavLink to=".." relative="path">
        <Button variant="ghost" disabled={isProcessing}>
          {cancelButtonLabel}
        </Button>
      </NavLink>

      <Button
        data-testid={`${variant}-grid-meter-button`}
        variant={'primary'}
        disabled={isConfirmDisabled}
        isLoading={isProcessing}
        onClick={onConfirm}
      >
        {actionButtonLabel}
      </Button>
    </div>
  );
};
