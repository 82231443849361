import { usePortalTranslation } from '@hooks/use-portal-translation';

import { SiteSettingsContent } from '@components/sites/site/settings/SiteSettingsContent';
import { useSiteDevicesContext } from '@context/SiteDevicesContext';
import ChargerIcon from '@icons/charger_96.svg';

import { NothingConfigured, Typography } from '@destination/components';

export const SiteSettings = () => {
  const { t } = usePortalTranslation();
  const {
    deviceData: { hasLoadManagement }
  } = useSiteDevicesContext();

  if (hasLoadManagement) {
    const title = t('labels.entity_settings', {
      entity: t('literals.site', { titleize: true })
    });

    return (
      <section>
        <Typography variant="sectionTitle" className="mb-4">
          {title}
        </Typography>
        <SiteSettingsContent />
      </section>
    );
  }

  const title = t('labels.no_configured', {
    entity: t('literals.load_management')
  });

  return (
    <section className="flex h-full items-center justify-center">
      <NothingConfigured title={title} icon={<ChargerIcon />} />
    </section>
  );
};
