import { randPastDate, randText, randTextRange } from '@ngneat/falso';

import { enumValueFaker } from '@utils/faker-utils';

import {
  ConnectionStatusEnum,
  DeviceInstanceParameters,
  GridMeter,
  HealthStatusEnum
} from '@omnis-pulse-types';

export class SiteGridMeterMockFactory {
  static createGridMeter(data?: Partial<DeviceInstanceParameters>): GridMeter {
    return {
      healthStatus: { status: enumValueFaker(HealthStatusEnum) },
      connectionStatus: {
        timestamp: randPastDate().toISOString(),
        status: enumValueFaker(ConnectionStatusEnum)
      },
      instanceId: randText(),
      parameters: {
        variant: {
          name: 'tcp',
          parameters: []
        },
        type: randTextRange({ min: 10, max: 15 }),
        productIdentity: {
          module: randTextRange({ min: 10, max: 15 }),
          key: randTextRange({ min: 10, max: 15 })
        },
        ...data
      }
    };
  }
}
