import { useNavigate } from 'react-router-dom';

import useChargerActiveSessions from '@api/sites/chargers/use-charger-active-sessions';
import { EvseActiveSessionDurationCell } from '@components/sites/site/insights/EvseActiveSessionDurationCell';
import { getIsChargerConnected } from '@utils/charger-utils';
import { formatDate, timeFormat } from '@utils/date-utils';
import { roundNumber } from '@utils/utils';

import { Grid, Typography } from '@destination/components';

import { Charger } from '@omnis-pulse-types';

import { EvseStatusOverview } from '../evse/EvseStatusOverview';
import { ChargerCellContent } from './ChargerCellContent';
import { ChargerNameAndSerialTableCell } from './ChargerNameAndSerialTableCell';
import { ChargerPowerTableCell } from './ChargerPowerTableCell';
import { ChargerStatusTableCell } from './ChargerStatusTableCell';
import { ConnectionStatusOverview } from './ConnectionStatusOverview';
import { SocketTypeCell } from './SocketTypeCell';

export interface IChargerOverviewRow {
  siteId?: string;
  charger: Charger;
  highlighted: string;
}

export const ChargerOverviewRow = ({
  siteId,
  charger,
  highlighted
}: IChargerOverviewRow) => {
  const navigate = useNavigate();

  const isChargerConnected = getIsChargerConnected(charger);

  const { isError, isLoading, sessions, mutate } = useChargerActiveSessions(
    isChargerConnected ? siteId : undefined,
    isChargerConnected ? charger.id : undefined
  );

  const { connectionStatus, evseStatuses, name, serialNumber } = charger;

  return (
    <Grid.TableRow
      data-testid={charger.name}
      variant="card"
      onClick={() => navigate(`devices/chargers/${charger.id}`)}
      className="cursor-pointer"
    >
      <ChargerStatusTableCell
        connectionStatus={connectionStatus}
        evseStatuses={evseStatuses}
      />
      <ChargerNameAndSerialTableCell
        name={name}
        serial={serialNumber}
        highlighted={highlighted}
      />
      <Grid.DataCell className="content-center">
        <ConnectionStatusOverview connectionStatus={connectionStatus} />
      </Grid.DataCell>
      <Grid.DataCell className="content-center">
        <ChargerCellContent
          connectionStatus={connectionStatus}
          evseStatuses={evseStatuses}
          render={evseStatus => <EvseStatusOverview status={evseStatus} />}
          isFirstCell
        />
      </Grid.DataCell>
      <Grid.DataCell className="content-center">
        <ChargerCellContent
          connectionStatus={connectionStatus}
          evseStatuses={evseStatuses}
          render={evse => <SocketTypeCell sockets={evse.sockets} />}
        />
      </Grid.DataCell>
      <ChargerPowerTableCell
        connectionStatus={connectionStatus}
        evseStatuses={evseStatuses}
        sessions={sessions}
        isLoading={isLoading}
        isError={isError}
        mutate={mutate}
      />
      <Grid.DataCell className="content-center">
        {!isError && (
          <ChargerCellContent
            connectionStatus={connectionStatus}
            evseStatuses={evseStatuses}
            sessions={sessions}
            render={(_evse, session) =>
              session?.soc !== undefined && session?.soc !== null ? (
                <Typography variant="smallText">{`${roundNumber(session.soc, 2)}%`}</Typography>
              ) : (
                <Typography variant="smallText">--</Typography>
              )
            }
          />
        )}
      </Grid.DataCell>
      <Grid.DataCell className="content-center">
        {!isError && (
          <ChargerCellContent
            connectionStatus={connectionStatus}
            evseStatuses={evseStatuses}
            sessions={sessions}
            render={(_evse, session) =>
              session ? (
                <Typography variant="smallText">
                  {formatDate(session.startTime, timeFormat)}
                </Typography>
              ) : (
                <div className="min-h-4" />
              )
            }
          />
        )}
      </Grid.DataCell>
      <Grid.DataCell className="content-center">
        {!isError && (
          <ChargerCellContent
            connectionStatus={connectionStatus}
            evseStatuses={evseStatuses}
            sessions={sessions}
            isLastCell
            render={(evse, session) =>
              session ? (
                <EvseActiveSessionDurationCell
                  charger={charger}
                  evse={evse}
                  session={session}
                />
              ) : (
                <div className="min-h-4" />
              )
            }
          />
        )}
      </Grid.DataCell>
    </Grid.TableRow>
  );
};
