import { useCallback, useEffect, useMemo, useState } from 'react';

import { DeviceModelParameters, OverridableEnum } from '@omnis-pulse-types';

import { ParameterInputField } from './ParameterInputField';

export interface ParameterInputsProps {
  parameters: DeviceModelParameters[];
  onParameterChange: (
    updatedParameters: { name: string; value: string }[]
  ) => void;
  setIsValidForm: (isValid: boolean) => void;
  isDisabled: boolean;
  localValues: { name: string; value: string }[] | undefined;
  fillInDefaultValues?: boolean;
}

export const ParameterInputs = ({
  parameters,
  onParameterChange,
  setIsValidForm,
  localValues,
  isDisabled,
  fillInDefaultValues = true
}: ParameterInputsProps) => {
  const [internalValues, setInternalValues] = useState(localValues || []);

  const handleParameterChange = useCallback(
    (key: string, value: string) => {
      const updatedValues = [...internalValues];
      const index = updatedValues.findIndex(local => local.name === key);

      if (index !== -1) {
        updatedValues[index].value = value;
      } else {
        updatedValues.push({ name: key, value });
      }
      setInternalValues(updatedValues);
      onParameterChange(updatedValues);
    },
    [internalValues, onParameterChange]
  );

  useEffect(() => {
    if (localValues) {
      setInternalValues(localValues);
    }
  }, [localValues]);

  useEffect(() => {
    const isValid = parameters.every(param => {
      const isRequired = param.overridable === OverridableEnum.MANDATORY;
      const value = internalValues.find(
        local => local.name === param.name
      )?.value;
      return !isRequired || (value && value.trim() !== '');
    });

    setIsValidForm(isValid);
  }, [internalValues, parameters, setIsValidForm]);

  const renderedInputs = useMemo(
    () =>
      parameters.map(param => {
        let localValue = undefined;
        if (
          internalValues?.find(localParam => localParam.name === param.name)
        ) {
          localValue = internalValues?.find(
            localParam => localParam.name === param.name
          )?.value;
        } else if (fillInDefaultValues) {
          localValue = param.defaultValue;
        }

        return (
          <ParameterInputField
            key={param.name}
            param={param}
            localValue={localValue}
            isDisabled={isDisabled}
            handleChange={handleParameterChange}
          />
        );
      }),
    [
      parameters,
      internalValues,
      fillInDefaultValues,
      isDisabled,
      handleParameterChange
    ]
  );

  return (
    <div className="[&>div:nth-child(odd)]:bg-[#FAFAFA] [&>div]:flex [&>div]:flex-col [&>div]:justify-between [&>div]:gap-y-2 [&>div]:rounded-[4px] [&>div]:py-2 [&>div]:pl-3 [&>div]:pr-[18px] [&>div]:md:flex-row [&>div]:md:items-center">
      {renderedInputs}
    </div>
  );
};
