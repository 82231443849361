import { usePortalTranslation } from '@hooks/use-portal-translation';
import { useNavigate, useParams } from 'react-router-dom';
import { mutate } from 'swr';

import { GRID_METERS_BY_SITE_ID_PATH } from '@api/paths';
import deleteDevice from '@api/sites/devices/delete-device';
import DeleteIcon from '@icons/delete_16.svg';

import { Button, useAlert, useDialog } from '@destination/components';

import { GridMeter } from '@omnis-pulse-types';

export const DeleteGridMeterButton = ({
  gridMeter
}: {
  gridMeter: GridMeter;
}) => {
  const navigate = useNavigate();
  const { id = '' } = useParams();

  const { trigger } = useDialog();
  const { notify, notifyError } = useAlert();

  const { t, t_generic } = usePortalTranslation('gridMeters');

  const handleClickDelete = () => {
    const deleteFailedMsg = t('messages.command_to_delete_grid_meter_failed');
    const deleteSuccessfulMsg = t(
      'messages.command_to_delete_grid_meter_successful'
    );
    const dialogHeader = `${t_generic('buttons.delete', { entity: t_generic('literals.grid_meter'), titleize: true })}?`;
    const dialogDescription = t('messages.delete_grid_meter_warning');
    const dialogConfirmButtonLabel = t_generic('buttons.delete', {
      titleize: true
    });

    const _notifyError = () => {
      notifyError({
        header: deleteFailedMsg,
        'data-testid': 'grid-meter-delete-failed-alert'
      });
    };

    const _notifySuccess = () => {
      notify({
        variant: 'success',
        header: deleteSuccessfulMsg,
        isDismissible: true,
        'data-testid': 'grid-meter-delete-successful-alert'
      });
    };

    const handleConfirm = async () => {
      const { error } = await deleteDevice(id, gridMeter.instanceId);

      if (error) {
        _notifyError();
      } else {
        _notifySuccess();
        await mutate(GRID_METERS_BY_SITE_ID_PATH(id));

        navigate('..', { relative: 'path' });
      }
    };

    trigger({
      header: dialogHeader,
      content: dialogDescription,
      confirmButton: dialogConfirmButtonLabel,
      variant: 'error',
      onConfirm: handleConfirm,
      onCancel: () => undefined,
      'data-testid': 'confirm-delete-gridMeter-dialog'
    });
  };

  return (
    <Button
      icon={<DeleteIcon />}
      variant="discreet"
      onClick={handleClickDelete}
    >
      {t_generic('buttons.delete', {
        entity: t_generic('literals.grid_meter'),
        titleize: true
      })}
    </Button>
  );
};
