import { usePortalTranslation } from '@hooks/use-portal-translation';

import { AutomaticSaveSwitch, Typography } from '@destination/components';

export interface ILocalInterfaceSwitch {
  isEnabled: boolean;
  onSave: (payload: { isEnabled: boolean }) => Promise<void>;
}

export const LocalInterfaceSwitch = ({
  isEnabled,
  onSave
}: ILocalInterfaceSwitch) => {
  const { t, t_generic } = usePortalTranslation();

  const handleSave = async (isEnabled: boolean) => {
    await onSave({ isEnabled });
  };

  const activeLabel = t_generic('literals.on', { titleize: true });
  const inactiveLabel = t_generic('literals.off', { titleize: true });
  const errorToastMessage = t('failed_to_save_settings', {
    entity: t_generic('literals.local_modbus_interface')
  });

  return (
    <>
      <div className="min-w-[200px]">
        <Typography>
          {t_generic('literals.local_modbus_interface', { titleize: true })}
        </Typography>
      </div>
      <AutomaticSaveSwitch
        initialCheckedValue={isEnabled}
        activeLabel={activeLabel}
        inactiveLabel={inactiveLabel}
        errorToastMessage={errorToastMessage}
        onSave={handleSave}
      />
    </>
  );
};
