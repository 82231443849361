import { useMemo } from 'react';

import { endOfDay, startOfDay } from 'date-fns';
import { useSearchParams } from 'react-router-dom';

export function useDateFilter() {
  const [searchParams, setSearchParams] = useSearchParams();

  const from = useMemo(() => {
    const newFrom = searchParams.get('from');

    if (newFrom) {
      return newFrom;
    }

    return startOfDay(new Date()).toISOString();
  }, [searchParams]);

  const to = useMemo(() => {
    const newTo = searchParams.get('to');

    if (newTo) {
      return newTo;
    }

    return endOfDay(new Date()).toISOString();
  }, [searchParams]);

  const handleFilter = (date?: Date) => {
    const params = new URLSearchParams(window.location.search);
    const selectedDate = date || new Date();

    params.set('from', startOfDay(selectedDate).toISOString());
    params.set('to', endOfDay(selectedDate).toISOString());

    setSearchParams(params);
  };

  return {
    from,
    to,
    handleFilter
  };
}
