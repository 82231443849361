import { useMemo, useState } from 'react';

import { usePortalTranslation } from '@hooks/use-portal-translation';

import useReports from '@api/reporting/use-reports';
import { PageHeader } from '@components/general/PageHeader';
import { Last2MonthsOverview } from '@components/reporting/Last2MonthsOverview';
import { ReportingTable } from '@components/reporting/ReportingTable';
import { TabLabel } from '@components/TabLabel';
import {
  months,
  transformDateToMonthAbbreviation
} from '@utils/reporting-utils';

import { DataError, Typography, YearSelect } from '@destination/components';

import { ModelUsageReport } from '@omnis-pulse-types';

export const calculateSums = (
  year: number,
  data: { [key: number]: ModelUsageReport[] }
) => {
  const organizationReports = data[year]?.filter(
    report => report.subjectType === 'Organization'
  );
  const combinedRecords = organizationReports?.flatMap(
    report => report.records || []
  );
  return combinedRecords
    ? transformDateToMonthAbbreviation(combinedRecords)
    : [];
};

export const getLastTwoMonths = (currentMonth: number, currentYear: number) => {
  if (currentMonth === 0) {
    return [
      { month: 10, year: currentYear - 1 }, // November of last year
      { month: 11, year: currentYear - 1 } // December of last year
    ];
  } else if (currentMonth === 1) {
    return [
      { month: 11, year: currentYear - 1 }, // December of last year
      { month: 0, year: currentYear } // January of current year
    ];
  }
  return [
    { month: currentMonth - 2, year: currentYear }, // Two months ago
    { month: currentMonth - 1, year: currentYear } // Last month
  ];
};

export const Reporting = () => {
  const { t, t_generic } = usePortalTranslation('reporting');
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth();

  const [selectedYear, setSelectedYear] = useState<number>(currentYear);

  const yearsToFetch = useMemo(
    () =>
      currentMonth === 0 || currentMonth === 1
        ? [currentYear - 1, currentYear]
        : [currentYear],
    [currentMonth, currentYear]
  );

  const { data, isLoading, isError } = useReports(yearsToFetch);

  // Data for the selected year
  const transformedData = useMemo(() => {
    const siteReports = data[selectedYear]?.filter(
      report => report.subjectType === 'Site'
    );
    return (
      siteReports?.map(report => ({
        ...report,
        records: report.records
          ? transformDateToMonthAbbreviation(report.records)
          : undefined
      })) || []
    );
  }, [selectedYear, data]);

  // Last two months' data relative to the current date
  const lastTwoMonths = useMemo(
    () => getLastTwoMonths(currentMonth, currentYear),
    [currentMonth, currentYear]
  );

  const lastTwoMonthsData = useMemo(() => {
    return lastTwoMonths.map(({ month, year }) => {
      const sums = calculateSums(year, data);
      const monthData = sums.find(record => record.date === months[month]);
      return {
        number: monthData?.value ?? 0,
        month: months[month]
      };
    });
  }, [data, lastTwoMonths]);

  const content = isError ? (
    <DataError />
  ) : (
    <>
      <div className="mt-6">
        <Last2MonthsOverview data={lastTwoMonthsData} />
      </div>
      <div className="mt-6">
        <YearSelect
          initialYear={new Date(currentYear, 0, 1)}
          maxYear={currentYear}
          onChange={year => setSelectedYear(year.getFullYear())}
        />
      </div>
      <div className="mt-4">
        <ReportingTable
          isLoading={isLoading}
          data={transformedData}
          sums={calculateSums(selectedYear, data)}
        />
      </div>
    </>
  );

  return (
    <>
      <TabLabel label={t_generic('labels.reporting', { titleize: true })} />
      <PageHeader title={t_generic('labels.reporting', { titleize: true })} />
      <Typography variant="sectionTitle">
        {t('subtitle', { titleize: true })}
      </Typography>
      <Typography variant="description" className="mt-2">
        {t('description', { titleize: true })}
      </Typography>
      {content}
    </>
  );
};
