import { useCallback, useEffect, useState } from 'react';

import { usePortalTranslation } from '@hooks/use-portal-translation';

import { GridMeterSettingsCard } from '@components/grid-meters/add-grid-meter/GridMeterSettingsCard';
import { useGridMeterContext } from '@context/GridMeterContext';

import { LoadingSkeleton } from '@destination/components';

import { DeviceModelParameters } from '@omnis-pulse-types';

export interface IGridMeterParameters {
  parameters: DeviceModelParameters[];
  extraParameters: DeviceModelParameters[] | undefined;
  isLoading: boolean;
  isDisabled: boolean;
  fillInDefaultValues?: boolean;
}

export const GridMeterParameters = ({
  parameters,
  extraParameters,
  isLoading,
  isDisabled,
  fillInDefaultValues = true
}: IGridMeterParameters) => {
  const { setIsValidForm } = useGridMeterContext();
  const { t } = usePortalTranslation();

  const {
    gridMeterParameters,
    updateGridMeterParameters,
    updateExtraParameters
  } = useGridMeterContext();

  const [isValidCommunicationsForm, setIsValidCommunicationsForm] =
    useState(false);
  const [isValidExtraForm, setIsValidExtraForm] = useState(
    !extraParameters || extraParameters.length === 0
  );

  useEffect(() => {
    const isValid = isValidCommunicationsForm && isValidExtraForm;
    setIsValidForm(isValid);
  }, [isValidCommunicationsForm, isValidExtraForm, setIsValidForm]);

  const handleChangeCommunicationSettings = useCallback(
    (params: { name: string; value: string }[]) => {
      const enteredParameters = gridMeterParameters.protocol?.parameters
        ? gridMeterParameters.protocol.parameters.map(param => ({
            name: param.name ?? '',
            value: param.value ?? ''
          }))
        : [];

      const uniqueParameters = Array.from(
        new Map(
          [...enteredParameters, ...params].map(p => [p.name, p])
        ).values()
      );

      updateGridMeterParameters(uniqueParameters);
    },
    [gridMeterParameters.protocol?.parameters, updateGridMeterParameters]
  );

  const handleChangeExtraParameters = useCallback(
    (params: { name: string; value: string }[]) => {
      const enteredParameters = gridMeterParameters.specifications
        ? gridMeterParameters.specifications.map(param => ({
            name: param.name ?? '',
            value: param.value ?? ''
          }))
        : [];

      const uniqueParameters = Array.from(
        new Map(
          [...enteredParameters, ...params].map(p => [p.name, p])
        ).values()
      );
      updateExtraParameters([...params, ...uniqueParameters]);
    },
    [gridMeterParameters.specifications, updateExtraParameters]
  );

  if (isLoading) {
    return <LoadingSkeleton height={200} />;
  }

  return (
    <>
      {parameters.length > 0 && (
        <GridMeterSettingsCard
          title={t('labels.entity_settings', {
            entity: t('literals.communication'),
            titleize: true
          })}
          parameters={parameters}
          onParameterChange={handleChangeCommunicationSettings}
          setIsValidForm={setIsValidCommunicationsForm}
          isDisabled={isDisabled}
          localValues={
            gridMeterParameters.protocol?.parameters as {
              name: string;
              value: string;
            }[]
          }
          fillInDefaultValues={fillInDefaultValues}
        />
      )}
      {extraParameters && extraParameters.length > 0 && (
        <GridMeterSettingsCard
          title={t('labels.entity_settings', {
            entity: t('literals.special'),
            titleize: true
          })}
          parameters={extraParameters}
          onParameterChange={handleChangeExtraParameters}
          setIsValidForm={setIsValidExtraForm}
          isDisabled={isDisabled}
          localValues={
            (gridMeterParameters.specifications as {
              name: string;
              value: string;
            }[]) ?? []
          }
          fillInDefaultValues={fillInDefaultValues}
        />
      )}
    </>
  );
};
