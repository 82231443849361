import { useMsal } from '@azure/msal-react';
import { usePortalTranslation } from '@hooks/use-portal-translation';

import LockIcon from '@icons/lock_96.svg';
import SignOutIcon from '@icons/sign_out_16.svg';

import { Button, Typography } from '@destination/components';

export const Unauthorized = ({ message }: { message: string }) => {
  const { t } = usePortalTranslation();
  const { instance } = useMsal();

  const handleLogout = async () => {
    await instance.logout();
  };
  return (
    <div className="flex h-screen items-center justify-center">
      <div className="w-full text-center">
        <div className="mb-8 flex justify-center" data-testid="lock-icon">
          <LockIcon />
        </div>
        <div className="mb-8 flex justify-center text-center">
          <Typography variant="sectionTitle" className="w-[450px]">
            {message}
          </Typography>
        </div>
        <div className="flex justify-center text-lg">
          <Button
            variant="accent"
            onClick={handleLogout}
            icon={<SignOutIcon />}
          >
            <Typography variant="smallTitle">
              {t('literals.sign_out')}
            </Typography>
          </Button>
        </div>
      </div>
    </div>
  );
};
