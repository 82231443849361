import { Card, Typography } from '@destination/components';

import { DeviceModelParameters } from '@omnis-pulse-types';

import { ParameterInputs } from './ParameterInputs';

export interface GridMeterSettingsCardProps {
  title: string;
  parameters: DeviceModelParameters[];
  onParameterChange: (
    updatedParameters: { name: string; value: string }[]
  ) => void;
  setIsValidForm: (isValid: boolean) => void;
  isDisabled: boolean;
  localValues: { name: string; value: string }[];
  fillInDefaultValues?: boolean;
}

export const GridMeterSettingsCard = ({
  title,
  parameters,
  onParameterChange,
  setIsValidForm,
  isDisabled,
  localValues,
  fillInDefaultValues = true
}: GridMeterSettingsCardProps) => (
  <Card
    data-testid={`grid-meter-settings-card-${title}`}
    className="max-w-[620px] py-6"
  >
    <div className="max-w-[620px] py-6">
      <Typography variant="smallTitle" className="mb-6 ml-3">
        {title}
      </Typography>
      <ParameterInputs
        parameters={parameters}
        onParameterChange={onParameterChange}
        setIsValidForm={setIsValidForm}
        isDisabled={isDisabled}
        localValues={localValues}
        fillInDefaultValues={fillInDefaultValues}
      />
    </div>
  </Card>
);
