import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';

import { IOption } from '@destination/components';

import {
  CreateDeviceForSiteRequestBody,
  DeviceCategory,
  DeviceModelParameters,
  ProductType
} from '@omnis-pulse-types';

export const configurableParameterNames = [
  'host',
  'port',
  'unit_id',
  'addressing_mode',
  'byte_order'
];

export const filterConfigurableParameters = (
  parameters: DeviceModelParameters[]
) => {
  return configurableParameterNames
    .map(name => parameters.find(param => param.name === name))
    .filter(param => param !== undefined);
};

interface GridMeterContextProps {
  manufacturer: IOption | null;
  setManufacturer: Dispatch<SetStateAction<IOption | null>>;
  type: IOption | null;
  setType: Dispatch<SetStateAction<IOption | null>>;
  productTypeIdentifier: ProductType['identity'] | null;
  setProductTypeIdentifier: Dispatch<
    SetStateAction<ProductType['identity'] | null>
  >;
  selectedProtocol: string;
  setSelectedProtocol: Dispatch<SetStateAction<string>>;
  gridMeterParameters: CreateDeviceForSiteRequestBody;
  updateGridMeterParameters: (
    updatedParameters: { name: string; value: string }[]
  ) => void;
  updateExtraParameters: (
    updatedParameters: { name: string; value: string }[]
  ) => void;
  isValidForm: boolean;
  setIsValidForm: Dispatch<SetStateAction<boolean>>;
  isProcessing: boolean;
  setIsProcessing: Dispatch<SetStateAction<boolean>>;
}

const GridMeterContext = createContext<GridMeterContextProps | undefined>(
  undefined
);

export const GridMeterProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [manufacturer, setManufacturer] = useState<IOption | null>(null);
  const [type, setType] = useState<IOption | null>(null);
  const [productTypeIdentifier, setProductTypeIdentifier] = useState<
    ProductType['identity'] | null
  >(null);
  const [selectedProtocol, setSelectedProtocol] = useState<string>('tcp');
  const [gridMeterParameters, setGridMeterParameters] =
    useState<CreateDeviceForSiteRequestBody>({
      deviceCategory: DeviceCategory.METER,
      product: type?.value as string,
      protocol: { variant: selectedProtocol }
    });
  const [isValidForm, setIsValidForm] = useState<boolean>(false);
  const [isProcessing, setIsProcessing] = useState(false);

  const updateGridMeterParameters = useCallback(
    (updatedParameters: { name: string; value: string }[]) => {
      if (
        JSON.stringify(updatedParameters) !==
        JSON.stringify(gridMeterParameters?.protocol?.parameters)
      ) {
        setGridMeterParameters(prev => ({
          ...prev,
          protocol: {
            ...prev.protocol,
            parameters: updatedParameters
          }
        }));
      }
    },
    [gridMeterParameters, setGridMeterParameters]
  );

  const updateExtraParameters = useCallback(
    (updatedExtraParameters: { name: string; value: string }[]) => {
      if (
        JSON.stringify(updatedExtraParameters) !==
        JSON.stringify(gridMeterParameters?.specifications)
      ) {
        setGridMeterParameters(prev => ({
          ...prev,
          specifications: updatedExtraParameters
        }));
      }
    },
    [gridMeterParameters, setGridMeterParameters]
  );

  useEffect(() => {
    setGridMeterParameters(prev => ({
      ...prev,
      protocol: { ...prev.protocol, variant: selectedProtocol }
    }));
  }, [selectedProtocol]);

  useEffect(() => {
    setGridMeterParameters(prev => ({
      ...prev,
      product: type?.value as string
    }));
  }, [type]);

  // Memoize the context value to prevent unnecessary re-renders
  const contextValue = useMemo(
    () => ({
      manufacturer,
      setManufacturer,
      type,
      setType,
      productTypeIdentifier,
      setProductTypeIdentifier,
      selectedProtocol,
      setSelectedProtocol,
      gridMeterParameters,
      updateExtraParameters,
      updateGridMeterParameters,
      isValidForm,
      setIsValidForm,
      isProcessing,
      setIsProcessing
    }),
    [
      manufacturer,
      type,
      productTypeIdentifier,
      selectedProtocol,
      gridMeterParameters,
      updateExtraParameters,
      updateGridMeterParameters,
      isValidForm,
      isProcessing
    ]
  );

  return (
    <GridMeterContext.Provider value={contextValue}>
      {children}
    </GridMeterContext.Provider>
  );
};

export const useGridMeterContext = () => {
  const context = useContext(GridMeterContext);
  if (!context) {
    throw new Error(
      'useGridMeterContext must be used within a GridMeterProvider'
    );
  }
  return context;
};
