import { useDecodedToken } from '@hooks/use-decoded-token';
import { usePortalTranslation } from '@hooks/use-portal-translation';

import useUserDetails from '@api/user-account/use-user-details';
import useUserOrganizations from '@api/user-account/use-user-organizations';
import { switchOrganization } from '@utils/msal-utils';

import { LoadingSkeleton, useAlert, UserMenu } from '@destination/components';

import { UserOrganizationDetails } from '@omnis-pulse-types';

export const UserDetails = () => {
  const { t, t_generic } = usePortalTranslation('account');

  const { decodedToken, isLoading: isLoadingDecodedToken } = useDecodedToken();
  const { organizations, isLoading: isLoadingOrganizations } =
    useUserOrganizations();
  const { userDetails, isLoading: isLoadingUserDetails } = useUserDetails();
  const { notifyError } = useAlert();

  if (isLoadingDecodedToken || isLoadingOrganizations || isLoadingUserDetails) {
    return (
      <div className="mt-auto">
        <LoadingSkeleton className="w-40 rounded" isDarker height={36} />
      </div>
    );
  }

  if (decodedToken === undefined || organizations === undefined) {
    return null;
  }

  const mapOrganizationDetails = (organization: UserOrganizationDetails) => ({
    id: organization.organizationOmnisId,
    name:
      organization.name ?? t_generic('literals.unknown', { titleize: true }),
    roles: (organization.roles || []).map(role =>
      t(`literals.${role.toLowerCase()}`)
    )
  });

  const currentOrganization = organizations.find(
    organization =>
      organization.organizationOmnisId === userDetails?.organization.omnisId
  );

  if (currentOrganization === undefined) {
    return null;
  }

  const otherOrganizations = organizations
    .filter(
      organization =>
        organization.organizationOmnisId !== userDetails?.organization.omnisId
    )
    .map(mapOrganizationDetails);

  const handleSwitchOrganization = async (organizationOmnisId: string) => {
    try {
      await switchOrganization(organizationOmnisId);
    } catch {
      notifyError({
        header: t('messages.could_not_switch_organization')
      });
    }
  };

  return (
    <div className="mt-auto">
      <UserMenu
        fullName={decodedToken.name}
        currentOrganization={mapOrganizationDetails(currentOrganization)}
        organizations={otherOrganizations}
        labels={{
          yourRole: t('messages.your_role'),
          yourRoles: t('messages.your_role_other'),
          more: t('messages.more')
        }}
        onSwitchOrganization={handleSwitchOrganization}
      />
    </div>
  );
};
