import { ReactNode } from 'react';

import { usePortalTranslation } from '@hooks/use-portal-translation';

import { useGridMeterContext } from '@context/GridMeterContext';

import {
  Card,
  LoadingSkeleton,
  RadioButton,
  Typography
} from '@destination/components';

export interface IGridMeterProtocolCard {
  isDisabled: boolean;
  isLoading: boolean;
  protocols: (string | undefined)[];
}

export const GridMeterProtocolCard = ({
  isDisabled,
  isLoading,
  protocols
}: IGridMeterProtocolCard) => {
  const { t, t_generic } = usePortalTranslation('gridMeters');

  const { selectedProtocol, setSelectedProtocol } = useGridMeterContext();

  const renderContent = (child: ReactNode) => {
    return (
      <Card
        data-testid="grid-meter-protocol-card"
        className="max-w-[620px] py-6"
      >
        <Typography variant="smallTitle" className="mb-4 ml-3">
          {t_generic('literals.protocol', { titleize: true })}
        </Typography>
        <div className="flex gap-3 bg-[#FAFAFA] px-3 py-4">{child}</div>
      </Card>
    );
  };
  if (isLoading) {
    return renderContent(<LoadingSkeleton height={20} />);
  }
  if (protocols.length === 0) {
    return null;
  } else {
    return renderContent(
      protocols.map(protocol => {
        return (
          <RadioButton
            key={protocol}
            label={t(`protocols.${protocol}`, { titleize: true })}
            checked={selectedProtocol === protocol}
            disabled={isDisabled}
            onChange={() => protocol && setSelectedProtocol(protocol)}
          />
        );
      })
    );
  }
};
