import { useCallback, useMemo } from 'react';

import { usePortalTranslation } from '@hooks/use-portal-translation';
import { useParams } from 'react-router-dom';

import useSiteDevicesProductTypes from '@api/sites/use-site-devices-product-types';
import { useGridMeterContext } from '@context/GridMeterContext';
import {
  getManufacturerOptions,
  getTypeOptions
} from '@utils/product-type-utils';

import { Card, IOption, Typography } from '@destination/components';

import { DeviceCategory, ProductType } from '@omnis-pulse-types';

import { GridMeterManufacturerSelector } from './GridMeterManufacturerSelector';
import { GridMeterTypeSelector } from './GridMeterTypeSelector';

export interface IGridMeterManufacturerAndTypeCard {
  isDisabled: boolean;
  isUnsupportedType: boolean;
}

export const GridMeterManufacturerAndTypeCard = ({
  isDisabled,
  isUnsupportedType
}: IGridMeterManufacturerAndTypeCard) => {
  const {
    manufacturer,
    setManufacturer,
    type,
    setType,
    setProductTypeIdentifier
  } = useGridMeterContext();

  const { id } = useParams();

  const { productTypes, isLoading, isError, mutate } =
    useSiteDevicesProductTypes(id, DeviceCategory.METER);

  const { t } = usePortalTranslation('gridMeters');

  const manufacturerAndTypeCardTitle = t('labels.choose_manufacturer_and_type');

  const manufacturerOptions: IOption[] = useMemo(
    () => getManufacturerOptions(productTypes || []),
    [productTypes]
  );

  const typeOptions: IOption[] = useMemo(
    () => getTypeOptions(productTypes || [], manufacturer),
    [manufacturer, productTypes]
  );

  const modelToIdentifierMap = useMemo(() => {
    const map: Record<string, ProductType['identity'] | null> = {};
    productTypes?.forEach(({ model, identity }) => {
      if (model) {
        map[model] = identity || null;
      }
    });
    return map;
  }, [productTypes]);

  const handleChangeManufacturer = useCallback(
    (option: IOption) => {
      setManufacturer(option);
      setType(null);
    },
    [setManufacturer, setType]
  );

  const handleChangeType = useCallback(
    (option: IOption) => {
      setType(option);
      setProductTypeIdentifier(modelToIdentifierMap[option.value] || null);
    },
    [setType, setProductTypeIdentifier, modelToIdentifierMap]
  );

  return (
    <Card className="max-w-[620px] py-6">
      <Typography variant="smallTitle" className="mb-6 ml-3">
        {manufacturerAndTypeCardTitle}
      </Typography>
      <div className="[&>div:nth-child(odd)]:bg-[#FAFAFA] [&>div]:flex [&>div]:flex-col [&>div]:justify-between [&>div]:gap-y-2 [&>div]:rounded-[4px] [&>div]:py-2 [&>div]:pl-3 [&>div]:pr-[18px] [&>div]:md:flex-row [&>div]:md:items-center">
        <GridMeterManufacturerSelector
          options={manufacturerOptions}
          isDisabled={isDisabled}
          isLoading={isLoading}
          isError={isError}
          selected={manufacturer}
          onChange={handleChangeManufacturer}
          onRefresh={() => mutate(undefined, true)}
        />
        <GridMeterTypeSelector
          options={typeOptions}
          isDisabled={manufacturer === null || isDisabled}
          selected={type}
          onChange={handleChangeType}
          isUnsupportedType={isUnsupportedType}
        />
      </div>
    </Card>
  );
};
