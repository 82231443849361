import { useDateFilter } from '@hooks/use-date-filter';
import { useParams, useSearchParams } from 'react-router-dom';

import useSiteEventLog from '@api/sites/use-site-event-log';
import { LevelFilterButton } from '@components/table/logs/LevelFilterButton';
import { LogsContainer } from '@components/table/logs/LogsContainer';

import { Card, DatePicker } from '@destination/components';

export const Logs = () => {
  const { id } = useParams();
  const { from, handleFilter } = useDateFilter();
  const [searchParams] = useSearchParams();

  const {
    logs,
    isLoading,
    isLoadingMore,
    isError,
    hasReachedEnd,
    handleLoadMore
  } = useSiteEventLog(id, searchParams);

  return (
    <>
      <div className="mb-3 flex gap-3">
        <DatePicker initialDate={new Date(from)} onChange={handleFilter} />
        <LevelFilterButton />
      </div>
      <Card className="!shadow-none">
        <LogsContainer
          isError={isError}
          isLoadingInitial={isLoading}
          isLoadingMore={isLoadingMore}
          isCompleted={hasReachedEnd}
          logs={logs}
          onLoadMore={handleLoadMore}
        />
      </Card>
    </>
  );
};
